<template>
  <div class="user-avatar">
    <div v-if="avatarUrl" class="user-avatar__active">
      <img :src="avatarUrl" alt="" />
      <label class="user-avatar__active-icon">
        <input type="file" @input="updateAvatar">
        <img src="@/assets/svg/icon-camera-white.svg" alt="" />
      </label>
    </div>
    <label v-else class="user-avatar__default">
      <input type="file" @input="updateAvatar">
      <img src="@/assets/svg/icon-camera-grey.svg" alt="" />
    </label>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "UserAvatar",

  props: {
    data: {
      type: Object,
      default: () => {},
    },
    get: {
      type: String,
      default: 'url_avatar'
    }
  },

  data() {
    return {
      avatarUrl: '',
    }
  },

  methods: {
    ...mapActions(['reqUploadAvatar']),

    updateAvatar({ target }) {
      this.reqUploadAvatar(target.files[0]).then((res) => {
        this.avatarUrl = URL.createObjectURL(target.files[0])
        this.$emit('update', res)
      })
    },
  },

  mounted() {
    setTimeout(() => {
      this.avatarUrl = this.data[this.get] ? this.data[this.get] : this.data.url
    }, 100)
  }
}
</script>

<style lang="scss" scoped>
.user-avatar {
  border: 1px solid #9A9A9A;
  width: 70px;
  height: 70px;
  border-radius: 114.583px;
  overflow: hidden;

  &__default {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    cursor: pointer;
  }

  &__active {
    position: relative;
    width: 100%;
    height: 100%;

    img:first-child {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  input {
    display: none
  }
}
</style>
