<template>
  <div class="user-data">
    <ui-loader v-model="visibleLoader" />
    <div v-if="!visibleLoader" class="user-data__form">
      <user-avatar :data="form.avatar" @update="form.avatar = $event" />
      <ui-select
          v-model="form.role"
          :options="getRoleOptions"
          :label="$t('role')"
          required-field
          @input="changeRole"
      />
      <ui-input
          v-if="form.role.name === $t('business') || form.role.name === $t('public-authority')"
          v-model="form.companyName"
          :label="getCompanyNameLabel"
          required-field
          :error="$v.form.companyName?.$error"
          :error-text="getCompanyNameErrorText"
      />
      <ui-input
          v-if="form.role.name === $t('business')"
          v-model="form.inn"
          :label="$t('tin')"
          required-field
          :error="$v.form.inn?.$error"
          :error-text="$t('error-inn')"
          v-mask="'############'"
          placeholder="XXXXXXXXXXXX"
      />
<!--      <ui-select-->
<!--          v-if="form.role.name === 'Бизнес' || form.role.name === 'Орган власти'"-->
<!--          v-model="form.city"-->
<!--          :label="form.role.name === 'Бизнес' ? 'Место нахождения организации/ИП' : 'Место нахождения органа власти'"-->
<!--          required-field-->
<!--          :error="$v.form.city.$error"-->
<!--          :error-text="form.role.name === 'Бизнес' ? 'Укажите место нахождения организации/ИП' : 'Укажите место нахождения органа власти'"-->
<!--          :options="getRegions"-->
<!--      />-->
      <div class="user-data__form-multiselect" v-if="form.role.name === $t('business') || form.role.name === $t('public-authority')">
        <label class="typo__label" for="multiSelect">{{ form.role.name === $t('business') ? $t('business-location') : $t('public-authority-location') }}</label>
        <Multiselect
            @search-change="getCityApi"
            id="multiSelect"
            v-model="form.city"
            :options="regions"
            placeholder=""
            :loading="isLoadingMultiSelect"
            :custom-label="getCityName"
            :close-on-select="true"
            :show-labels="false"
        >
          <template slot="noOptions" >
            {{ $t('enter-address') }}
          </template>
        </Multiselect>
      </div>
      <h2 v-if="form.role.name === $t('business') || form.role.name === $t('public-authority')">{{ getDetails }}</h2>
      <ui-input
          v-model="form.surname"
          :label="$t('last-name')"
          required-field
          :error="$v.form.surname?.$error"
          :error-text="$t('enter-your-last-name')"
      />
      <ui-input
          v-model="form.name"
          :label="$t('first-name')"
          required-field
          :error="$v.form.name?.$error"
          :error-text="$t('enter-your-first-name')"
      />
      <ui-input
          v-model="form.middleName"
          :label="$t('middle-name')"
      />
      <ui-input
          v-model="form.nickName"
          :label="$t('nickname')"
          required-field
          :error="$v.form.nickName?.$error || errors.nickName"
          :error-text="$t('enter-nickname')"
          @input="errors.nickName = null"
      />
<!--      <ui-select-->
<!--          v-if="form.role.name !== 'Бизнес' && form.role.name !== 'Орган власти'"-->
<!--          v-model="form.city"-->
<!--          label="Место проживания"-->
<!--          required-field-->
<!--          :error="$v.form.city.$error"-->
<!--          error-text="Укажите место проживания"-->
<!--          :options="getRegions"-->
<!--      />-->
      <div class="user-data__form-multiselect" v-if="form.role.name !== $t('business') && form.role.name !== $t('public-authority')">
        <label class="typo__label" for="multiSelect">{{ $t('place-of-residence') }}</label>
        <Multiselect
            @search-change="getCityApi"
            id="multiSelect"
            v-model="form.city"
            :options="regions"
            placeholder=""
            :loading="isLoadingMultiSelect"
            :custom-label="getCityName"
            :close-on-select="true"
            :show-labels="false"
        >
          <template slot="noOptions" >
            {{ $t('enter-address') }}
          </template>
        </Multiselect>
      </div>
      <ui-input
          v-model="form.phone"
          :label="$t('phone-number')"
          :error="$v.form.phone?.$error || errors.phone"
          :error-text="$t('incorrect-phone-number')"
          @input="errors.phone = null"
          required-field
          v-mask="'+7(###) ###-##-##'"
          placeholder="+7(XXX) XXX-XX-XX"
      />
      <ui-input
          v-if="form.role.name === $t('business') || form.role.name === $t('public-authority')"
          v-model="form.document"
          :label="form.role.name === $t('business') ? $t('label-document') : $t('label-document-number')"
          :placeholder="form.role.name === $t('business') ? $t('placeholder-document-number-for-company') : $t('placeholder-document-number-for-all')"
          required-field
          :error="$v.form.document?.$error"
          :error-text="$t('document-number')"
      />
      <ui-input
          v-if="form.role.name !== $t('business') && form.role.name !== $t('public-authority')"
          v-model="form.documentNumber"
          :label="form.role.name === $t('inspector') ? $t('certificate-number') : $t('diploma-number')"
          :placeholder="form.role.name === $t('eco-lawyer') ? $t('education-diploma') : $t('certificate-of-public-inspector')"
          required-field
          :error="$v.form.documentNumber?.$error"
          :error-text="form.role.name === $t('eco-lawyer') ? $t('enter-the-diploma-number') :$t('specify-certificate-number')"
      />
      <ui-date-picker
          v-model="form.issueDate"
          required-field
          :label=" form.role.name === $t('eco-lawyer') ? $t('date-of-issue') : $t('validity-period')"
          :error="$v.form.issueDate?.$error"
          :error-text="$t('validity-period')"
          :placeholder="form.role.name === $t('eco-lawyer') ? $t('date-format') : $t('to-date-format')"
          :disabled-range="rangeDate"
      />
      <ui-checkbox
          v-if="form.role.name === $t('business')"
          v-model="form.accessIndefinitely"
          @input="changeIndefinitely"
      >
        <p>{{ $t('indefinitely') }}</p>
      </ui-checkbox>
      <div class="user-data__form-upload upload">
        <p :class="['upload__text', { error: $v.form.documentFile?.$error }]">
          {{ getDocumentFileText }}
        </p>
        <div class="upload__wrap">
          <label>
            <input type="file" ref="file" @input="uploadDocument" accept="image/*" >
            <span class="upload__wrap-btn">
              <img src="@/assets/svg/icon-plus-white.svg" alt="" />
            </span>
          </label>
          <a
              v-for="(item, key) in form.documentSrc"
              :href="item.url"
              target="_blank"
              style="display: flex"
              :key="key"
              class="upload__wrap-file"
          >
            <img
                src="@/assets/svg/icon-docs-grey.svg"
                alt=""
            />
            <div class="upload__wrap-file-close" @click="deleteFile($event, key)">
              <img
                  src="@/assets/svg/close/white.svg"
                  alt=""
              />
            </div>
            <div class="upload__wrap-file-info">
              <p>{{ item.name || item.originalname }}</p>
              <span>{{ getFullDate(new Date())}}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="user-data__actions">
      <ui-checkbox v-model="form.accessProcessing" :error="$v.form.accessProcessing?.$error">
        <p v-html="$t('agree-for-info')"></p>
      </ui-checkbox>
      <ui-button
          @click="submit"
          :disabled="$v.form.$anyError || shouldDisabled"
      >{{ $t('register') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {getFullDate} from "@/utils/general";
import Multiselect from 'vue-multiselect'
import axios from "axios";
import UserAvatar from "@/components/common/UserAvatar.vue";

export default {
  name: 'UserData',
  components: {
    UserAvatar,
    Multiselect,
    UiInput: () => import("@/components/ui/UiInput"),
    UiButton: () => import("@/components/ui/UiButton"),
    UiDatePicker: () => import("@/components/ui/UiDatePicker"),
    UiCheckbox: () => import("@/components/ui/UiCheckbox"),
    UiSelect: () => import("@/components/ui/UiSelect"),
    UiLoader: () => import("@/components/ui/UiLoader")
  },

  validations() {
    return {
      form: this.getRules
    }
  },

  data() {
    return {
      form: {
        role: { name: this.$t('inspector'), value: 'Inspector' },
        name: '',
        surname: '',
        nickName: '',
        middleName: '',
        city: {},
        phone: '',
        documentNumber: '',
        issueDate: null,
        documentFile: [],
        documentSrc: [],
        accessProcessing: null,
        accessIndefinitely: null,
        avatar: {},
        companyName: '',
        inn: '',
        document: '',
      },
      visibleLoader: true,
      errors: {},
      getRoleOptions: [
        { name: this.$t('inspector'), value: 'Inspector' },
        { name: this.$t('business'), value: 'Company' },
        { name: this.$t('public-authority'), value: 'Government' },
        { name: this.$t('eco-lawyer'), value: 'Lawyer' },
      ],
      getFullDate,
      regions: [],
      isLoadingMultiSelect: false
    }
  },

  mounted() {
    setTimeout(() => {
      console.log(this.$v.form.name, '1111');
    }, 5000)
    document.addEventListener('keydown', this.enter)

    // Promise.all([
    //   this.reqRegions()
    // ]).then(() => {
    //   this.visibleLoader = false
    //   this.form.city = this.getRegions[0]
    // });
    this.visibleLoader = false
  },

  computed: {
    ...mapGetters(['getRegions']),

    rulesInspector() {
      return {
        name: {
          required,
        },
        surname: {
          required,
        },
        nickName: {
          required,
        },
        city: {
          required,
        },
        documentNumber: {
          required,
        },
        accessProcessing: {
          required,
        },
        phone: {
          required,
          minLength: minLength(17),
        },
        documentFile: {
          required
        },
        issueDate: {
          required
        }
      }
    },

    rulesCompany() {
      return {
        name: {
          required,
        },
        surname: {
          required,
        },
        nickName: {
          required,
        },
        city: {
          required,
        },
        document: {
          required,
        },
        accessProcessing: {
          required,
        },
        companyName: {
          required,
        },
        inn: {
          required,
          minLength: minLength(10),
        },
        documentFile: {
          required
        },
        phone: {
          required,
          minLength: minLength(17),
        },
        issueDate: {
          required,
        },
      }
    },

    rulesCompanyCheckBox() {
      return {
        name: {
          required,
        },
        surname: {
          required,
        },
        nickName: {
          required,
        },
        city: {
          required,
        },
        document: {
          required,
        },
        accessProcessing: {
          required,
        },
        companyName: {
          required,
        },
        inn: {
          required,
          minLength: minLength(10),
        },
        documentFile: {
          required
        },
        phone: {
          required,
          minLength: minLength(17),
        },
      }
    },

    rulesLawyer() {
      return {
        name: {
          required,
        },
        surname: {
          required,
        },
        nickName: {
          required,
        },
        city: {
          required,
        },
        documentNumber: {
          required,
        },
        accessProcessing: {
          required,
        },
        documentFile: {
          required
        },
        phone: {
          required,
          minLength: minLength(17),
        },
        issueDate: {
          required,
        },
      }
    },

    rulesGovernment() {
      return {
        name: {
          required,
        },
        surname: {
          required,
        },
        nickName: {
          required,
        },
        city: {
          required,
        },
        document: {
          required,
        },
        accessProcessing: {
          required,
        },
        companyName: {
          required,
        },
        documentFile: {
          required
        },
        phone: {
          required,
          minLength: minLength(17),
        },
        issueDate: {
          required,
        },
      }
    },

    getRules() {
      return this.form.role.name === this.$t('inspector') ? this.rulesInspector : '' ||
      this.form.role.name === this.$t('business') && this.form.accessIndefinitely ? this.rulesCompanyCheckBox : '' ||
      this.form.role.name === this.$t('business') ? this.rulesCompany : '' ||
      this.form.role.name === this.$t('eco-lawyer') ? this.rulesLawyer : '' ||
      this.form.role.name === this.$t('public-authority') ? this.rulesGovernment : ''
    },

    getCityLabel() {
      return (
          this.form.role.name === this.$t('public-authority') ? this.$t('public-authority-location') :
          this.form.role.name === this.$t('inspector') || this.form.role.name === this.$t('eco-lawyer') ? this.$t('place-of-residence') : this.$t('business-location')
      )
    },

    getCompanyNameLabel() {
      return this.form.role?.name === this.$t('public-authority') ? this.$t('name-of-authority') : this.$t('name-of-organization')
    },

    getCompanyNameErrorText() {
      return this.form.role?.name === this.$t('public-authority') ? this.$t('specify-the-name-of-the-authority') : this.$t('specify-the-name-of-the-organization')
    },

    getDocumentFileText() {
      return this.form.role.name === this.$t('inspector') ? this.$t('attach-your-ID') : '' ||
      this.form.role.name === this.$t('business') ? this.$t('attach-primary-state-registration') : '' ||
      this.form.role.name === this.$t('eco-lawyer') ? this.$t('attach-diploma') : '' ||
      this.form.role.name === this.$t('public-authority') ? this.$t('attach-document') : ''
    },

    shouldDisabled() {
      let empty = this.getFields.find(prop => !this.form[prop])
      return empty !== undefined
    },

    fieldCompany() {
      return ['name', 'surname', 'nickName', 'city', 'document', 'accessProcessing', 'companyName', 'inn', 'documentFile', 'phone', 'issueDate']
    },

    fieldCompanyCheckBox() {
      return ['name', 'surname', 'nickName', 'city', 'document', 'accessProcessing', 'companyName', 'inn', 'documentFile', 'phone']
    },

    fieldInspector() {
      return [ 'name', 'surname', 'nickName', 'city', 'documentNumber', 'accessProcessing', 'phone', 'documentFile', 'issueDate']
    },

    fieldLawyer() {
      return ['name', 'surname', 'nickName', 'city', 'documentNumber', 'accessProcessing', 'documentFile', 'phone', 'issueDate']
    },

    fieldGovernment() {
      return ['name', 'surname', 'nickName', 'city', 'document', 'accessProcessing', 'companyName', 'documentFile', 'phone', 'issueDate',]
    },

    getFields() {
      return this.form.role.name === this.$t('inspector') ? this.fieldInspector : '' ||
      this.form.role.name === this.$t('business') && this.form.accessIndefinitely ? this.fieldCompanyCheckBox : '' ||
      this.form.role.name === this.$t('business') ? this.fieldCompany : '' ||
      this.form.role.name === this.$t('eco-lawyer') ? this.fieldLawyer : '' ||
      this.form.role.name === this.$t('public-authority') ? this.fieldGovernment : ''
    },

    getDetails () {
      return this.form.role.name === this.$t('business') ? this.$t('data-of-the-representative') : this.$t("representative's-data")
    },
  },

  methods: {
    ...mapMutations(['setAuth']),
    ...mapActions(['reqRegions', 'reqUpload', 'update', 'reqUploadAvatar']),
    getCityName({ name }) {
      return name
    },
    uploadDocument({ target }) {
      this.reqUpload({
        file: target.files[0],
        type: "document",
      }).then((res) => {
        this.form.documentFile.push(res.data.filename)
        this.form.documentSrc.push(res.data)
        this.$refs.file.value = null
      })
    },

    deleteFile(e, key) {
      e.preventDefault();
      this.form.documentFile = this.form.documentFile.filter((elem, idx) => idx !== key);
      this.form.documentSrc = this.form.documentSrc.filter((elem, idx) => idx !== key);
    },

    updateAvatar({ target }) {
      this.reqUploadAvatar(target.files[0]).then((res) => {
        this.form.avatar = res
        console.log(res)
      })
    },

    changeRole() {
      this.$v.$reset()
      this.form.phone = '',
      this.form.documentNumber = '',
      this.form.issueDate = null,
      this.form.documentFile = [],
      this.form.companyName = '',
      this.form.inn = '',
      this.form.document = ''
      this.form.accessIndefinitely = null
    },

    dataFormat() {
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        return [year, month, day].join('-');
      }

      return this.form.issueDate ? formatDate(this.form.issueDate) : null
    },

    submit() {
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        localStorage.setItem('registerStep', '3')
        this.update({
          firstName: this.form.name,
          lastName: this.form.surname,
          middleName: this.form.middleName,
          login: this.form.nickName,
          phone: this.form.phone,
          avatar: this.form.avatar.filename || null,
          regionId: this.form.city.id,
          // districtId: this.form.city.districts[0].id,
          role: this.form.role.value,
          documentNumber: this.form.documentNumber,
          documentIssueDate: this.dataFormat(),
          documentFile: this.form.documentFile,
          companyName: this.form.companyName,
          taxNumber: this.form.inn,
          email: this.$store.state.authForm.email,
          city: this.form.city.name,
        }).then(() => {
          this.$router.push('/')
          this.setAuth(true)
          // UserStore.auth = true
        }).catch((err) => {
          if(err.response.data.constraint === 'users_login_unique') {
            this.errors = {
              nickName: true
            }
          }
          if(err.response.data.constraint === 'users_phone_unique') {
            this.errors = {
              phone: true
            }
          }
        })
      }
    },
    enter(e) {
      if(e.keyCode === 13) {
        this.submit()
      }
    },

    getCityApi(query) {
      this.regions = []
      this.isLoadingMultiSelect = true
      axios.get(`https://geocode-maps.yandex.ru/1.x?apikey=0449798b-7041-4730-8bbd-9cc5bbe78c42&format=json&lang=ru_RU&results=50&locality=kind&geocode=${query}`)
          .then((res) => {
            res.data.response.GeoObjectCollection.featureMember.forEach((val, idx) => {
              const { Address } = val.GeoObject.metaDataProperty.GeocoderMetaData
              if(Address.country_code === 'RU') {
                const obj = {
                  id: idx + 1,
                  name: val.GeoObject.description ? val.GeoObject.description + ',' + val.GeoObject.name : val.GeoObject.name
                }
                this.regions.push(obj)
              }
            })
            this.isLoadingMultiSelect = false
          })
          .catch(() => {
            this.isLoadingMultiSelect = false
          })
    },

    rangeDate(date) {
      return this.form.accessIndefinitely ? date >= '' : this.form.role?.name === this.$t('eco-lawyer') ? date >= new Date() : date <= new Date ;
    },

    changeIndefinitely() {
      this.form.issueDate = null
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.user-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #1B1A1F;
    }

    ::v-deep .ui-checkbox {
      align-self: flex-end;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: #343432;
      }
    }

    &-multiselect {
      display: flex;
      flex-direction: column;
      gap: 10px;

      label {
        margin-left: 30px;
        font-size: 12px;
        color: #343432;
      }
    }

    &-avatar {
      width: 70px;
      height: 70px;
      border: 1px solid #9A9A9A;
      border-radius: 114.583px;
      margin: 0 auto;
      object-fit: cover;
    }

    &-wrap {
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #F5F5F5;
      border: 1px solid #9A9A9A;
      border-radius: 114.583px;
      margin: 0 auto;

      input {
        display: none;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid #D9D9D9;
    padding-top: 30px;

    ::v-deep .red-link {
      color: #CE2121;
    }
  }
}

.upload {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    margin-left: 30px;

    &.error {
      color: #E21F1F;
    }

    &:after {
      content: '*';
      color: #E21F1F;
      margin-left: -4px;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: auto;
    right: 1rem;
    width: 100%;
    height: 100%;
    padding: 10px 0;

    &::-webkit-scrollbar {
      width: 20px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #9A9A9A;
      border-radius: 10px;
    }

    &-btn {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E21F1F;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }

    input {
      display: none;
    }


    &-file {
      position: relative;
      flex-direction: column;
      align-items: center;
      max-width: 116px;
      min-width: 114px;
      gap: 10px;

      &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #1B1A1F;
          word-break: break-all;
        }

        span {
          font-weight: 400;
          font-size: 10px;
          line-height: 112.7%;
          color: #BFBDBD;
          width: 58px;
          text-align: center;
        }
      }

      &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 24px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #E21F1F;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
::v-deep .ui-select__inner-text {
  font-family: "Gotham Pro Regular";
}
::v-deep .ui-select__options-item {
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__tags {
  border-radius: 25px !important;
  border: 1px solid #9A9A9A !important;
  max-height: 198px !important;
}
::v-deep .multiselect__option {
  font-size: 12px !important;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__content-wrapper {
  border-radius: 10px;
}
::v-deep .multiselect__single {
  font-size: 12px;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__option--selected.multiselect__option--highlight {
  background: none !important;
}
::v-deep .multiselect__option--selected {
  color: #E21F1F !important;
}
::v-deep .multiselect__option--highlight {
  background: none !important;
  color: #E21F1F !important;
}
::v-deep .multiselect__input {
  font-size: 12px !important;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__spinner {
  border-radius: 28px;
}
::v-deep .user-avatar {
  margin: 0 auto !important;
}
.typo__label {
  display: flex;

  &:after {
    content: '*';
    color: #CE2121;

  }
}
</style>
